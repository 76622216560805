<template>
  <div>
    <v-overlay :value="loading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-container v-if="!loading">
      <v-row>
        <v-col md="6">
          <h4 class="text-h4">{{ event.event_name }}</h4>
        </v-col>
        <v-col md="6" class="text-right">
          <v-btn
            color="primary"
            elevation="1"
            class="ma-1"
            :disabled="!subscribers || subscribers.length === 0"
            @click="enrollUsers"
          >
            Zapisz użytkowników
          </v-btn>
          <v-btn
            color="primary"
            :loading="loadingGenerate"
            elevation="1"
            class="ma-1"
            :disabled="!event.schedules || event.schedules.length === 0"
            @click="generateDocs"
          >
            Generuj dziennik
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col md="4">
          W dniach:
          <strong
            >{{ event.event_start_date }} - {{ event.event_end_date }}</strong
          >
        </v-col>
      </v-row>
      <v-row class="mb-4">
        <v-col>
          <strong>{{ event.event_location.location_name }}</strong
          ><br />
          {{ event.event_location.location_town }}
          {{ event.event_location.location_address }}
        </v-col>
      </v-row>

      <v-card class="mb-4">
        <v-card-title>Zaświadczenia</v-card-title>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                v-model="event.description.certNumberI"
                label="Cześć I"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="event.description.certNumberII"
                label="Cześć II"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="event.description.certNumberIII"
                label="Cześć III"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="event.description.certNumberIV"
                label="Cześć IV"
                required
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                v-model="event.description.certNumberV"
                label="Cześć V"
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col md="12" class="text-center">
              <v-btn
                color="primary"
                elevation="1"
                class="mr-4"
                :loading="loadingCertificates"
                @click="saveEventCertificates"
              >
                Zapisz numery zaświadczeń
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mb-4">
        <v-card-title>
          <v-container>
            <v-row>
              <v-col>
                Harmonogram
              </v-col>
            </v-row>
          </v-container>
        </v-card-title>

        <v-simple-table>
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">Blok</th>
                <th class="text-left">Data od</th>
                <th class="text-left">Data do</th>
                <th class="text-left">Wykładowca</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="item in event.schedules"
                :key="item.blockType + item.dateA + item.dataB + item.trener"
              >
                <td>
                  <v-select
                    :items="blockTypes"
                    v-model="item.blockType"
                    :rules="requiredField"
                    label="Blok zajęć"
                    outlined
                    dense
                    class="pt-6"
                  >
                  </v-select>
                </td>
                <td>
                  <v-menu
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Dzień pierwszy"
                        v-model="item.dateA"
                        outlined
                        class="pt-6"
                        dense
                        v-bind="attrs"
                        v-on="on"
                        :rules="requiredField"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.dateA"
                      locale="pl-pl"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <td>
                  <v-menu
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        label="Dzień drugi"
                        v-model="item.dateB"
                        outlined
                        class="pt-6"
                        dense
                        v-bind="attrs"
                        v-on="on"
                        :rules="requiredField"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="item.dateB"
                      locale="pl-pl"
                    ></v-date-picker>
                  </v-menu>
                </td>
                <td>
                  <v-select
                    :items="teachers"
                    v-model="item.teacher"
                    :rules="requiredField"
                    label="Trener"
                    outlined
                    dense
                    class="pt-6"
                  >
                  </v-select>
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>

        <v-container>
          <v-row>
            <v-col md="12" class="text-center">
              <v-btn
                color="primary"
                class="ma-1"
                :disabled="!event.schedules || event.schedules.length > 0"
                @click="newSchedule"
              >
                Utwórz harmonogram
              </v-btn>
              <v-btn
                color="primary"
                elevation="1"
                class="ma-1"
                :disabled="!event.schedules || event.schedules.length === 0"
                :loading="loadingSchedules"
                @click="saveEventSchedule"
              >
                Zapisz harmonogram
              </v-btn>
            </v-col>
          </v-row>
        </v-container>
      </v-card>

      <v-card class="mb-4">
        <v-card-title>Uczestnicy</v-card-title>
        <v-data-table
          :headers="headers"
          :items="subscribers"
          item-key="booking_id"
          :loading="loading"
          class="elevation-1"
        >
          <template slot="item.index" scope="props">
            {{ props.index + 1 }}
          </template>

          <template v-slot:item.name="{ item }">
            {{ item.first_name }} {{ item.last_name }}
          </template>

          <template v-slot:item.bur="{ item }">
            <v-icon v-if="item.bur">
              mdi-check
            </v-icon>
          </template>

          <template v-slot:item.action="{ item }">
            <v-btn
              class="ma-1"
              color="primary"
              outlined
              v-bind:href="
                'https://simp.edu.pl/wp-admin/edit.php?post_type=event&page=events-manager-bookings&event_id=' +
                  item.event_id +
                  '&booking_id=' +
                  item.booking_id
              "
              target="_blank"
            >
              Zobacz zgłoszenie
              <v-icon right>
                mdi-launch
              </v-icon>
            </v-btn>
          </template>
        </v-data-table>
      </v-card>
      <SnackbarStatus
        :show="snackbar.show"
        :color="snackbar.color"
        :message="snackbar.message"
      />
    </v-container>
  </div>
</template>

<script>
// @ is an alias to /src
import EventsService from "@/api/EventsService";
import SnackbarStatus from "@/components/SnackbarStatus.vue";
import common from "@/mixins/common";
import moment from "moment";

export default {
  name: "EventEdit",
  mixins: [common],
  components: {
    SnackbarStatus
  },
  data: () => ({
    teachers: [
      "Piotr Gębiś",
      "Józef Szczepański",
      "Tadeusz Siutaj",
      "Sławomir Zachara",
      "Krzysztof Pikul",
      "Józef Krzeszowiec",
      "Krzysztof Sączawa",
      "Tomasz Kociubiński",
      "Ryszard Sobol",
      "Remigiusz Jeruzel"
    ],
    blockTypes: [],
    event: null,
    loadingCertificates: false,
    loadingSchedules: false,
    loadingGenerate: false,
    subscribers: [],
    headers: [
      { text: "", value: "index", width: "20px" },
      { text: "Imię i nazwisko", value: "name" },
      { text: "Miejsce urodzenia", value: "city" },
      { text: "Pesel", value: "pesel" },
      { text: "Telefon", value: "phone" },
      { text: "E-mail", value: "user_email" },
      { text: "BUR", value: "bur" },
      { text: "", value: "action", width: "100px" }
    ],
    requiredField: [v => !!v || "Pole wymagane"]
  }),
  methods: {
    generateDocs() {
      this.loadingGenerate = true;
      EventsService.generateDocs(this.event.id)
        .then(res => {
          this.loadingGenerate = false;

          const url = window.URL.createObjectURL(new Blob([res]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "dziennik.zip");
          document.body.appendChild(link);
          link.click();

          console.log(res);
        })
        .catch(error => {
          console.log(error);
          this.loadingGenerate = false;
          this.showSnackbar("Request to API failed!", "warning");
        });
    },
    saveEventSchedule() {
      this.loadingSchedules = true;
      EventsService.saveEventSchedule(this.event.id, this.event.schedules)
        .then(res => {
          this.loadingSchedules = false;
          this.showSnackbar("Zapisano harmonogram", "green");
          console.log(res);
        })
        .catch(error => {
          console.log(error);
          this.loadingSchedules = false;
          this.showSnackbar("Request to API failed!", "warning");
        });
    },
    saveEventCertificates() {
      this.loadingCertificates = true;
      EventsService.saveEventCertificates(this.event.id, this.event.description)
        .then(res => {
          this.loadingCertificates = false;
          this.showSnackbar("Zapisano numery zaświadczeń", "green");
          console.log(res);
        })
        .catch(error => {
          console.log(error);
          this.loadingCertificates = false;
          this.showSnackbar("Request to API failed!", "warning");
        });
    },
    enrollUsers() {
      this.$router.push({
        name: "MoodleAdd",
        params: {
          users: this.subscribers
        }
      });
    },
    newSchedule() {
      for (var i = 0; i < this.blockTypes.length; i++) {
        let d1 = new Date(this.event.event_start_date);
        d1.setDate(d1.getDate() + i * 7);
        let d2 = new Date(this.event.event_start_date);
        d2.setDate(d2.getDate() + 1 + i * 7);
        this.event.schedules.push({
          blockType: this.blockTypes[i],
          dateA: moment(String(d1)).format("YYYY-MM-DD"),
          dateB: moment(String(d2)).format("YYYY-MM-DD")
        });
      }
    },
    loadEvent(id) {
      this.loading = true;
      EventsService.loadEventById(id)
        .then(res => {
          console.log(res);
          this.event = res;

          EventsService.loadSubsribersByEventId(id)
            .then(res => {
              console.log(res);
              this.loading = false;
              this.subscribers = res;
            })
            .catch(error => {
              console.log(error);
              this.showSnackbar("Request to API failed!", "warning");
            });
        })
        .catch(error => {
          console.log(error);
          this.showSnackbar("Request to API failed!", "warning");
        });
    }
  },
  created() {
    this.loadEvent(this.$route.params.id);

    EventsService.loadBlocktypes()
      .then(res => {
        console.log(res);
        this.blockTypes = res;
      })
      .catch(error => {
        console.log(error);
        this.showSnackbar("Request to API failed!", "warning");
      });
  }
};
</script>
